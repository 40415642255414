import {
  Flex,
  Item,
  View,
  TooltipTrigger,
  ActionButton,
  Tooltip,
  ButtonGroup,
  Button,
  Text,
  DialogContainer,
  ComboBox,
  TagGroup,
  DateRangePicker,
  Tabs,
  TabList,
  TabPanels,
  useAsyncList,
  AlertDialog,
  TextArea,
  SearchField,
} from "@adobe/react-spectrum";
import React, { useState, useEffect } from "react";
import { getLocalTimeZone, today } from "@internationalized/date";
import {
  error as ErrorToast,
  success as SuccessToast,
} from "@react/react-spectrum/Toast";
import { useHistory, useParams } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import CloseCircle from "@spectrum-icons/workflow/CloseCircle";
import CheckmarkCircle from "@spectrum-icons/workflow/CheckmarkCircle";
import CheckmarkCircleOutline from "@spectrum-icons/workflow/CheckmarkCircleOutline";
import Pending from "@spectrum-icons/workflow/Pending";
import Refresh from "@spectrum-icons/workflow/Refresh";
import EditInLight from "@spectrum-icons/workflow/EditInLight";
import Checkmark from "@spectrum-icons/workflow/Checkmark";
import Close from "@spectrum-icons/workflow/Close";
import ViewDetail from "@spectrum-icons/workflow/ViewDetail";
import Visibility from "@spectrum-icons/workflow/Visibility";
import { GTMH, ECH, DNB, ACCOUNT } from "../../../constants/Workflow";
import {
  HierarchyWorkflowPath,
  // WorkflowHierarchyManagementPath
} from "../../../constants/Routes";
import { HMSWorkflowColumns } from "../../../constants/Columns";
import {
  APPROVED,
  PENDING,
  DRAFT,
  AUTO_APPROVED,
  CHANGE_NOTIFICATION,
  REJECTED,
  //   REJECTED,
} from "../../../constants/Status";
import Table from "../../../components/Common/Table";
import ViewWorkflowDetailsDialog from "../../../components/Dialog/HMS/ViewWorkflowDetailsDialog";
import ViewWorkflowTasks from "../../../components/Dialog/HMS/ViewWorkflowTasksDialog";
import LoadingDialog from "../../../components/Dialog/LoadingDialog";
import { workflowApi } from "../../../api/workflowApi";
import useUserProfile from "../../../context/user-context";
import { ADMIN, OPERATOR, SUPERUSER, USER } from "../../../constants/RoleType";
import useValidUserAccess from "../../../hooks/useValidUserAccess";
import { HMS } from "../../../constants/CamFunctions";

export default function ManageWorkflows() {
  const [selectedWfs, setSelectedWfs] = useState(new Set([])); // keys in table which are selected
  // const [disabledKeys, setDisabledKeys] = useState([]);
  const [viewDNBChanges, setViewDNBChanges] = useState(false);
  const [viewWfTasks, setViewWfTasks] = useState(false);
  const [viewWfDetails, setViewWfDetails] = useState({});
  const [isManualGrouping, setIsManualGrouping] = useState(false);
  const [isAccountUpdate, setIsAccountUpdate] = useState(false);
  const [inputRejectionReason, setInputRejectionReason] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [isSearchLoading, setSearchLoading] = useState("");
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [searchedWfId, setSearchedWfId] = useState("");

  // wf type combobox + taglist
  const [wfTypes, setWfTypes] = useState([]);
  const [selectedWfTypes, setSelectedWfTypes] = useState([]);
  const [wfTypeField, setWfTypeField] = useState({
    selectedKey: "",
    inputValue: "All",
  });
  // wf subtype combobox + taglist
  const [wfSubtypes, setWfSubtypes] = useState([]);
  const [selectedWfSubtypes, setSelectedWfSubtypes] = useState([]);
  const [wfSubtypeField, setWfSubtypeField] = useState({
    selectedKey: "",
    inputValue: "All",
  });
  // wf status combobox + taglist
  const [wfStatus, setWfStatus] = useState([]);
  const [selectedWfStatus, setSelectedWfStatus] = useState([]);
  const [wfStatusField, setWfStatusField] = useState({
    selectedKey: "",
    inputValue: "All",
  });

  const wfColumns = HMSWorkflowColumns();
  const { authState } = useOktaAuth();
  const { user } = useUserProfile();
  const history = useHistory();
  const params = useParams();

  const wfSearchResultSize = 10;

  const isHmsActionAllowed = useValidUserAccess(
    [ADMIN, SUPERUSER, OPERATOR, USER],
    HMS
  );

  // modifies date based on days to add + strips hours from time
  const modifyDate = (date, daysToAdd) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + daysToAdd);
    newDate.setHours(0);
    const dateToString = newDate.toISOString();
    const stripHours = dateToString
      .substring(0, dateToString.indexOf("T"))
      .concat("T00:00:00.000Z");
    return stripHours;
  };

  // removes duplicate items from list in table
  const removeDuplicates = (objects) => {
    const uniqueObjects = Array.from(
      new Set(objects.map((obj) => JSON.stringify(obj)))
    ).map((str) => JSON.parse(str));

    return uniqueObjects;
  };

  const [fetchWfsRequest, setFetchWfsRequest] = useState({
    wf_type_list: [],
    wf_subtype_list: [],
    status_list: [],
    user: user?.userId,
    user_panel: false,
    // admin_panel: true,
    admin_panel: false,
    number_of_records: wfSearchResultSize,
    start_date_time: "1999-01-01T00:00:00.000Z",
    end_date_time: modifyDate(today(getLocalTimeZone()).toDate(), 2),
  });

  useEffect(() => {
    if (params?.workflowId) {
      setSearchedWfId(params?.workflowId);
      setFetchWfsRequest({
        ...fetchWfsRequest,
        admin_panel: false,
        user_panel: false,
        wf_id: params?.workflowId,
      });
    }
  }, [user, params]);

  const tabs = [
    { id: "all", name: "All Workflows" },
    { id: "pending", name: "Pending My Approval" },
    { id: "my_requests", name: "My Requests" },
  ];

  const onComboboxInputChange = (value, setFieldFunc) => {
    setFieldFunc((prevState) => ({
      inputValue: value,
      selectedKey: value === "" ? null : prevState.selectedKey,
    }));
  };

  useEffect(() => {
    // set workflow type picker options
    workflowApi
      .fetchDropdownOptions([{ field_name: "wf_type" }], authState.accessToken)
      .then((data) => {
        setWfTypes(data);
      })
      .catch((error) => {
        ErrorToast(
          error.response?.data?.message || "Failed to get workflows!",
          {
            timeout: 5000,
          }
        );
      });

    // set workflow status picker options
    workflowApi
      .fetchDropdownOptions([{ field_name: "status" }], authState.accessToken)
      .then((data) => {
        setWfStatus(data);
      })
      .catch((error) => {
        ErrorToast(
          error.response?.data?.message || "Failed to get workflows!",
          {
            timeout: 5000,
          }
        );
      });

    setFetchWfsRequest({ ...fetchWfsRequest, user: user?.userId });
  }, [user]);

  // when selected wf types changes, update subtype picker
  useEffect(() => {
    if (selectedWfTypes.length > 0) {
      workflowApi
        .fetchDropdownOptions(
          selectedWfTypes.map((wf) => ({
            field_name: "wf_subtype",
            parent_field_value: wf.id,
          })),
          authState.accessToken
        )
        .then((data) => {
          setWfSubtypes(data);
        })
        .catch((error) => {
          ErrorToast(
            error.response?.data?.message || "Failed to get workflows!",
            {
              timeout: 5000,
            }
          );
        });
    }
    setWfSubtypeField({
      selectedKey: "",
      inputValue: "All",
    });
  }, [selectedWfTypes]);

  useEffect(() => {
    setFetchWfsRequest({
      ...fetchWfsRequest,
      wf_type_list: selectedWfTypes.map((wfType) => wfType.id),
    });
  }, [selectedWfTypes]);

  useEffect(() => {
    setFetchWfsRequest({
      ...fetchWfsRequest,
      wf_subtype_list: selectedWfSubtypes.map((wfType) => wfType.id),
    });
  }, [selectedWfSubtypes]);

  useEffect(() => {
    setFetchWfsRequest({
      ...fetchWfsRequest,
      status_list: selectedWfStatus.map((wfType) => wfType.id),
    });
  }, [selectedWfStatus]);

  const list = useAsyncList({
    initialFilterText: "",
    async load({ filterText, cursor }) {
      let json = {
        results: [],
      };
      const request = cursor || fetchWfsRequest;
      if (filterText !== "") {
        setSearchLoading("loading");
        json = await workflowApi.fetchAllWorkflows(
          request,
          authState.accessToken
        );
      }
      const results = json?.results || [];
      // setDisabledKeys(
      //   results
      //     .filter((wf) => !(wf.can_approve && wf.status === PENDING))
      //     .map((wf) => wf.id)
      // );

      setSearchLoading("");
      return {
        items: results,
        cursor:
          results.length < wfSearchResultSize
            ? null
            : {
                ...fetchWfsRequest,
                last_record_created_date_time:
                  results[results.length - 1]?.created_date_time,
              },
      };
    },
    getKey: (item) => item?.id,
  });

  useEffect(() => {
    list.setFilterText(fetchWfsRequest);
  }, [fetchWfsRequest]);

  const handleSelectionChange = (tabSelection) => {
    if (tabSelection === "pending")
      setFetchWfsRequest({
        ...fetchWfsRequest,
        admin_panel: true,
        user_panel: false,
      });
    if (tabSelection === "my_requests")
      setFetchWfsRequest({
        ...fetchWfsRequest,
        admin_panel: false,
        user_panel: true,
      });
    if (tabSelection === "all")
      setFetchWfsRequest({
        ...fetchWfsRequest,
        admin_panel: false,
        user_panel: false,
      });
  };

  const renderStatus = (status) => {
    let statusIcon = (
      <CloseCircle aria-label="Rejected requests" color="negative" />
    );

    if (status === PENDING)
      statusIcon = <Pending aria-label="Pending requests" color="notice" />;
    else if (status === APPROVED)
      statusIcon = (
        <CheckmarkCircle aria-label="Approved requests" color="positive" />
      );
    else if (status === DRAFT)
      statusIcon = <EditInLight aria-label="Draft requests" />;
    else if (status === AUTO_APPROVED)
      statusIcon = (
        <CheckmarkCircleOutline
          aria-label="Approved requests"
          color="positive"
        />
      );
    else if (status === CHANGE_NOTIFICATION)
      statusIcon = <Visibility aria-label="Change notification" />;

    return statusIcon;
  };

  const handleViewWfDetails = (row) => {
    let isReadOnly = true;
    if (
      (row.status === PENDING && row.can_approve) ||
      (row.status === DRAFT && user?.userId === row.created_by)
    )
      isReadOnly = false;
    else isReadOnly = true;

    if (row.contains_task) {
      setViewWfTasks(true);
      setViewWfDetails(row);
    } else {
      if (row.wf_type === ECH || row.wf_type === GTMH) {
        if (row.wf_subtype === "hierarchy-system (overridden)") {
          setIsManualGrouping(false);
          setIsAccountUpdate(false);
          setViewWfDetails(row);
          setViewDNBChanges(true);
        } else {
          history.push({
            pathname: HierarchyWorkflowPath.concat("/", row.wf_id),
            state: {
              isReadOnly,
              row,
            },
          });
        }
      }

      if (row.wf_type === DNB) {
        setIsManualGrouping(false);
        setIsAccountUpdate(false);
        setViewWfDetails(row);
        setViewDNBChanges(true);
      }

      if (row.wf_type === ACCOUNT) {
        if (row.wf_subtype === "account-grouping-user") {
          setIsManualGrouping(true);
          setIsAccountUpdate(false);
          setViewWfDetails(row);
          setViewDNBChanges(true);
        }
        if (row.wf_subtype === "account-update-system") {
          setIsAccountUpdate(true);
          setIsManualGrouping(false);
          setViewWfDetails(row);
          setViewDNBChanges(true);
        }
      }
    }
  };

  const renderCell = (colKey, row) => {
    if (colKey === "status") {
      return renderStatus(row.status);
    }
    if (colKey === "details") {
      if (!(row.contains_task && row.status === "Rejected")) {
        return (
          <TooltipTrigger>
            <ActionButton isQuiet onPress={() => handleViewWfDetails(row)}>
              <View>
                <ViewDetail color="informative" />
              </View>
            </ActionButton>
            <Tooltip>View Workflow Details</Tooltip>
          </TooltipTrigger>
        );
      }
    }
    if (colKey === "comments" && row.status === REJECTED)
      return (
        <span // eslint-disable-line jsx-a11y/no-static-element-interactions
          style={{ cursor: "text", WebkitUserSelect: "text" }}
          onPointerDown={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <Text>{`${row.comments} - ${row.rejection_reason}`}</Text>
        </span>
      );
    return (
      <span // eslint-disable-line jsx-a11y/no-static-element-interactions
        style={{ cursor: "text", WebkitUserSelect: "text" }}
        onPointerDown={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <Text>{row[colKey]}</Text>
      </span>
    );
  };

  const handleBulkApproval = (selectedWfIds) => {
    setIsPageLoading(true);
    workflowApi
      .approveWorkflows(
        { workflow_id_list: selectedWfIds, user_id: user?.userId },
        authState.accessToken
      )
      .then((data) => {
        setIsPageLoading(false);
        const successfulApprovals = [];
        const unsuccessfulApprovals = [];

        Object.keys(data).forEach((wf) => {
          if (data[wf]) successfulApprovals.push(wf);
          else unsuccessfulApprovals.push(wf);
        });

        if (successfulApprovals.length > 0) {
          SuccessToast(
            `Successfully approved ${successfulApprovals.length} workflow(s)!`,
            {
              timeout: 5000,
            }
          );
        }
        if (unsuccessfulApprovals.length > 0) {
          ErrorToast(
            `Error approving ${unsuccessfulApprovals.length} workflow(s)`,
            {
              timeout: 5000,
            }
          );
        }
        setFetchWfsRequest({ ...fetchWfsRequest });
        setSelectedWfs([]);
      })
      .catch((error) => {
        setIsPageLoading(false);
        ErrorToast(
          error.response?.data?.message || "Failed to approve workflows!",
          {
            timeout: 5000,
          }
        );
      });
  };

  const handleBulkRejection = (selectedWfIds, reason) => {
    setIsPageLoading(true);
    workflowApi
      .rejectWorkflows(
        {
          workflow_id_list: selectedWfIds,
          user_id: user?.userId,
          ...(reason !== "" && { rejection_reason: reason }),
        },
        authState.accessToken
      )
      .then((data) => {
        setIsPageLoading(false);
        const successfulRejections = [];
        const unsuccessfulRejections = [];

        Object.keys(data).forEach((wf) => {
          if (data[wf]) successfulRejections.push(wf);
          else unsuccessfulRejections.push(wf);
        });

        if (successfulRejections.length > 0) {
          SuccessToast(
            `Successfully rejected ${successfulRejections.length} workflow(s)!`,
            {
              timeout: 5000,
            }
          );
        }
        if (unsuccessfulRejections.length > 0) {
          setIsPageLoading(false);
          ErrorToast(
            `Error rejecting ${unsuccessfulRejections.length} workflow(s)`,
            {
              timeout: 5000,
            }
          );
        }
        setFetchWfsRequest({ ...fetchWfsRequest });
        setSelectedWfs([]);
      })
      .catch((error) => {
        ErrorToast(
          error.response?.data?.message || "Failed to reject workflows!",
          {
            timeout: 5000,
          }
        );
      });
  };

  return (
    <>
      {!isHmsActionAllowed && (
        <Flex margin="size-300" gap="size-125" direction="column" width="90%">
          <View marginTop="size-200">
            You are not authorized to access this feature. To gain access please
            subscribe to <b>GRP-HMS</b> under the Groups tab. Select role{" "}
            <b>user</b> for read-only access and <b>admin</b> for edit access.
          </View>
        </Flex>
      )}
      {isHmsActionAllowed && (
        <>
          <LoadingDialog isOpen={isPageLoading} />
          <Flex margin="size-300" gap="size-125" direction="column" width="90%">
            <Flex>
              <Flex gap="size-150" direction="column">
                <Flex gap="size-150">
                  <Flex direction="column">
                    <ComboBox
                      label="Type"
                      defaultItems={wfTypes}
                      selectedKey={wfTypeField.selectedKey}
                      inputValue={wfTypeField.inputValue}
                      onSelectionChange={(val) => {
                        if (val) {
                          setSelectedWfTypes([
                            ...selectedWfTypes,
                            wfTypes.find((type) => type.id === val),
                          ]);
                          setWfTypes(wfTypes.filter((type) => type.id !== val));
                          setWfTypeField({ ...wfTypeField, inputValue: "" });
                        }
                      }}
                      onInputChange={(val) => {
                        onComboboxInputChange(val, setWfTypeField);
                      }}
                      width="150px"
                    >
                      {(item) => <Item>{item.name}</Item>}
                    </ComboBox>
                    {selectedWfTypes.length > 0 && (
                      <View maxWidth="150px">
                        <TagGroup
                          onRemove={(val) => {
                            const selectedId = val.values().next().value;
                            setWfTypes([
                              ...wfTypes,
                              selectedWfTypes.find(
                                (type) => type.id === selectedId
                              ),
                            ]);
                            setSelectedWfTypes(
                              selectedWfTypes.filter(
                                (type) => type.id !== selectedId
                              )
                            );
                            setWfTypeField({
                              ...wfTypeField,
                              inputValue:
                                selectedWfTypes.length === 1 ? "All" : "",
                            });
                            // clear subtype filter when wf types are removed
                            setSelectedWfSubtypes([]);
                          }}
                          items={selectedWfTypes}
                          aria-label="Selected"
                          allowsRemoving
                        >
                          {(item) => (
                            <Item key={item.id} textValue={item.name}>
                              <Text>{item.name}</Text>
                            </Item>
                          )}
                        </TagGroup>
                      </View>
                    )}
                  </Flex>
                  <Flex direction="column">
                    <ComboBox
                      label="Subtype"
                      defaultItems={wfSubtypes}
                      selectedKey={wfSubtypeField.selectedKey}
                      inputValue={wfSubtypeField.inputValue}
                      onSelectionChange={(val) => {
                        if (val) {
                          setSelectedWfSubtypes([
                            ...selectedWfSubtypes,
                            wfSubtypes.find((type) => type.id === val),
                          ]);
                          setWfSubtypes(
                            wfSubtypes.filter((type) => type.id !== val)
                          );
                          setWfSubtypeField({
                            ...wfSubtypeField,
                            inputValue: "",
                          });
                        }
                      }}
                      onInputChange={(val) => {
                        onComboboxInputChange(val, setWfSubtypeField);
                      }}
                      width="200px"
                    >
                      {(item) => <Item>{item.name}</Item>}
                    </ComboBox>
                    {selectedWfSubtypes.length > 0 && (
                      <View maxWidth="200px">
                        <TagGroup
                          onRemove={(val) => {
                            const selectedId = val.values().next().value;
                            setWfSubtypes([
                              ...wfSubtypes,
                              selectedWfSubtypes.find(
                                (type) => type.id === selectedId
                              ),
                            ]);
                            setSelectedWfSubtypes(
                              selectedWfSubtypes.filter(
                                (type) => type.id !== selectedId
                              )
                            );
                            setWfSubtypeField({
                              ...wfSubtypeField,
                              inputValue:
                                selectedWfSubtypes.length === 1 ? "All" : "",
                            });
                          }}
                          items={selectedWfSubtypes}
                          aria-label="Selected"
                          allowsRemoving
                        >
                          {(item) => (
                            <Item key={item.id} textValue={item.name}>
                              <Text>{item.name}</Text>
                            </Item>
                          )}
                        </TagGroup>
                      </View>
                    )}
                  </Flex>
                  <Flex direction="column">
                    <ComboBox
                      label="Status"
                      defaultItems={wfStatus}
                      selectedKey={wfStatusField.selectedKey}
                      inputValue={wfStatusField.inputValue}
                      onSelectionChange={(val) => {
                        if (val) {
                          setSelectedWfStatus([
                            ...selectedWfStatus,
                            wfStatus.find((type) => type.id === val),
                          ]);
                          setWfStatus(
                            wfStatus.filter((type) => type.id !== val)
                          );
                          setWfStatusField({
                            ...wfStatusField,
                            inputValue: "",
                          });
                        }
                      }}
                      onInputChange={(val) =>
                        onComboboxInputChange(val, setWfStatusField)
                      }
                      width="150px"
                    >
                      {(item) => <Item>{item.name}</Item>}
                    </ComboBox>
                    {selectedWfStatus.length > 0 && (
                      <View maxWidth="150px">
                        <TagGroup
                          onRemove={(val) => {
                            const selectedId = val.values().next().value;
                            setWfStatus([
                              ...wfStatus,
                              selectedWfStatus.find(
                                (type) => type.id === selectedId
                              ),
                            ]);
                            setSelectedWfStatus(
                              selectedWfStatus.filter(
                                (type) => type.id !== selectedId
                              )
                            );
                            setWfStatusField({
                              ...wfStatusField,
                              inputValue:
                                selectedWfStatus.length === 1 ? "All" : "",
                            });
                          }}
                          items={selectedWfStatus}
                          aria-label="Selected"
                          allowsRemoving
                        >
                          {(item) => (
                            <Item key={item.id} textValue={item.name}>
                              <Text>{item.name}</Text>
                            </Item>
                          )}
                        </TagGroup>
                      </View>
                    )}
                  </Flex>
                  <DateRangePicker
                    label="Date Range"
                    maxValue={today(getLocalTimeZone())}
                    defaultValue={{
                      end: today(getLocalTimeZone()),
                    }}
                    onChange={(value) =>
                      setFetchWfsRequest((prev) => ({
                        ...prev,
                        start_date_time: modifyDate(value.start.toDate(), 0),
                        end_date_time: modifyDate(value.end.toDate(), 1),
                      }))
                    }
                  />
                  <SearchField
                    label="Search Wf Id"
                    placeholder="Search by wf id"
                    value={searchedWfId}
                    onChange={setSearchedWfId}
                    onSubmit={(val) => {
                      setFetchWfsRequest({ ...fetchWfsRequest, wf_id: val });
                    }}
                    onClear={() => {
                      setSearchedWfId("");
                      setFetchWfsRequest({ ...fetchWfsRequest, wf_id: "" });
                    }}
                    width="200px"
                  />
                  <Flex marginTop="25px">
                    <TooltipTrigger>
                      <ActionButton
                        isQuiet
                        onPress={() =>
                          setFetchWfsRequest({
                            ...fetchWfsRequest,
                            wf_id: searchedWfId,
                          })
                        }
                      >
                        <Refresh />
                      </ActionButton>
                      <Tooltip>Refresh Workflows</Tooltip>
                    </TooltipTrigger>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Tabs
            aria-label="Workflow Views"
            margin="size-300"
            marginTop="size-0"
            width="90%"
            direction="row"
            items={tabs}
            defaultSelectedKey="all"
            onSelectionChange={handleSelectionChange}
          >
            <Flex>
              <TabList flex="1 1 auto">
                {(item) => <Item>{item.name}</Item>}
              </TabList>
              <div
                style={{
                  display: "flex",
                  flex: "0 0 auto",
                  borderBottom:
                    "var(--spectrum-alias-border-size-thick) solid var(--spectrum-global-color-gray-300)",
                }}
              >
                <ButtonGroup>
                  <Button
                    variant="primary"
                    isDisabled={selectedWfs.size === 0}
                    onClick={() => {
                      const selectedWfIds = [];
                      selectedWfs.forEach((wfKey) =>
                        selectedWfIds.push(
                          list.items.find((obj) => obj.id === wfKey).wf_id
                        )
                      );
                      handleBulkApproval(selectedWfIds);
                    }}
                  >
                    <Checkmark />
                    <Text>Approve</Text>
                  </Button>

                  <Button
                    variant="negative"
                    isDisabled={selectedWfs.size === 0}
                    onClick={() => setInputRejectionReason(true)}
                  >
                    <Close />
                    <Text>Reject</Text>
                  </Button>
                </ButtonGroup>
              </div>
            </Flex>
            <TabPanels>
              {(item) => (
                <Item id={item.id}>
                  <Flex
                    marginTop="size-50"
                    UNSAFE_style={{
                      backgroundColor: "white",
                      borderRadius: "0.5rem",
                    }}
                    direction="row"
                    height={
                      list.items > 0 ? "static-size-7000" : "static-size-6000"
                    }
                  >
                    <Table
                      columns={wfColumns}
                      rows={removeDuplicates(list.items)}
                      loadingState={
                        isSearchLoading !== ""
                          ? isSearchLoading
                          : list.loadingState
                      }
                      onLoadMore={list.loadMore}
                      renderCell={renderCell}
                      selectionMode="multiple"
                      density="compact"
                      selectedKeys={selectedWfs}
                      onSelectionChange={setSelectedWfs}
                      disabledKeys={list.items
                        .filter(
                          (wf) =>
                            !(
                              wf.can_approve &&
                              wf.status === PENDING &&
                              !wf.contains_task
                            )
                        )
                        .map((wf) => wf.id)}
                    />
                  </Flex>
                </Item>
              )}
            </TabPanels>
          </Tabs>
          <DialogContainer onDismiss={() => setViewDNBChanges(false)}>
            {viewDNBChanges && (
              <ViewWorkflowDetailsDialog
                details={viewWfDetails}
                handleApproval={handleBulkApproval}
                handleRejection={handleBulkRejection}
                isManualGrouping={isManualGrouping}
                isAccountUpdate={isAccountUpdate}
              />
            )}
          </DialogContainer>
          <DialogContainer
            onDismiss={() => setViewWfTasks(false)}
            // type="fullscreen"
          >
            {viewWfTasks && <ViewWorkflowTasks details={viewWfDetails} />}
          </DialogContainer>
          <DialogContainer onDismiss={() => setInputRejectionReason(false)}>
            {inputRejectionReason && (
              <AlertDialog
                title="Reject request"
                variant="destructive"
                primaryActionLabel="Yes"
                cancelLabel="No"
                onPrimaryAction={() => {
                  const selectedWfIds = [];
                  selectedWfs.forEach((wfKey) =>
                    selectedWfIds.push(
                      list.items.find((obj) => obj.id === wfKey).wf_id
                    )
                  );
                  handleBulkRejection(selectedWfIds, rejectionReason);
                }}
              >
                <Flex direction="column" gap="size-150">
                  <Text>
                    Are you sure you want to reject this request? Please confirm
                    by providing reason for rejecting the request.
                  </Text>
                  <TextArea
                    label="Reason"
                    width="100%"
                    value={rejectionReason}
                    onChange={setRejectionReason}
                  />
                </Flex>
              </AlertDialog>
            )}
          </DialogContainer>
        </>
      )}
    </>
  );
}