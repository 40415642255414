import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Form,
  Heading,
  TextField,
  useDialogContainer,
} from "@adobe/react-spectrum";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

export default function EditDunsDialog({ dunsId, handleSaveDuns }) {
  const dialog = useDialogContainer();
  const [newDunsId, setNewDunsId] = useState(null);
  const [isValidDuns, setIsValidDuns] = useState("valid");

  const handleSave = () => {
    if (newDunsId.replace(/\s+/g, "") === "") handleSaveDuns(null);
    else handleSaveDuns(newDunsId.padStart(11, "0"));
    dialog.dismiss();
  };

  const handleCancel = () => {
    dialog.dismiss();
  };

  useEffect(() => {
    if (newDunsId !== null) {
      if (newDunsId === "") setIsValidDuns("valid");
      else if (/^[0-9]+$/.test(newDunsId)) {
        if (newDunsId.length > 11) setIsValidDuns("invalid");
        else setIsValidDuns("valid");
      } else setIsValidDuns("invalid");
    }
  }, [newDunsId]);

  return (
    <Dialog height="295px">
      <Heading>Update Duns</Heading>
      <Divider />
      <Content>
        <Form labelPosition="side" width="100%">
          <TextField
            autoFocus
            label="Updated Duns"
            defaultValue={dunsId}
            onChange={setNewDunsId}
            isRequired
            validationState={isValidDuns}
            errorMessage="Please input a valid duns number (numeric &amp; <= 11 digits)"
          />
        </Form>
      </Content>
      <ButtonGroup>
        <Button onPress={handleCancel}>Cancel</Button>
        <Button
          onPress={handleSave}
          isDisabled={
            newDunsId === null ||
            newDunsId === dunsId ||
            isValidDuns === "invalid"
          }
        >
          Save
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}

EditDunsDialog.propTypes = {
  dunsId: PropTypes.string.isRequired,
  handleSaveDuns: PropTypes.func.isRequired,
};
