import {
  ActionButton,
  Flex,
  Item,
  Picker,
  SearchField,
  MenuTrigger,
  Menu,
  DialogContainer,
  DateRangePicker,
  Text,
  Link as V3Link,
  useAsyncList,
  View,
} from "@adobe/react-spectrum";
import React, { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { getLocalTimeZone, today } from "@internationalized/date";
import {
  success as SuccessToast,
  error as ErrorToast,
} from "@react/react-spectrum/Toast";
import MoreIcon from "@spectrum-icons/workflow/MoreSmallList";
import { useOktaAuth } from "@okta/okta-react";
import { HIERARCHY_ECH } from "../../../constants/Workflow";
import Table from "../../../components/Common/Table";
import { OrphanAccountsColumn } from "../../../constants/Columns";
import GetORGDetailsDialog from "../../../components/Dialog/HMS/GetORGDetailsDialog";
import EditDunsDialog from "../../../components/Dialog/HMS/EditDunsDialog";
import LoadingDialog from "../../../components/Dialog/LoadingDialog";
import AssociateExistingOrphanDialog from "../../../components/Dialog/HMS/AssociateExistingOrphanDialog";
import useUserProfile from "../../../context/user-context";
import { hierarchyApi } from "../../../api/hierarchyApi";
import { goldenRecordApi } from "../../../api/goldenRecordApi";
import useValidUserAccess from "../../../hooks/useValidUserAccess";
import { ADMIN, OPERATOR, SUPERUSER, USER } from "../../../constants/RoleType";
import { HMS } from "../../../constants/CamFunctions";

export default function OrphanAccountManagement() {
  const [selectedOrphanAccountId, setSelectedOrphanAccountId] = useState(null);
  const [selectedOrphan, setSelectedOrphan] = useState({});
  const [orgDetailsOpen, setOrgDetailsOpen] = useState(false);
  const [callORGDetail, setCallORGDetail] = useState(false);
  const [orgDetailsId, setOrgDetailsId] = useState();
  const [orgDetailsDuns, setOrgDetailsDuns] = useState();
  const [orgSourceSystems, setOrgSourceSystems] = useState([]);
  const [dialog, setDialog] = useState("");
  const [isPageLoading, setPageLoading] = useState(false);
  const [isSearchLoading, setSearchLoading] = useState("");
  const { authState } = useOktaAuth();
  const { user } = useUserProfile();

  const searchResultSize = 10;

  const isHmsActionAllowed = useValidUserAccess(
    [ADMIN, SUPERUSER, OPERATOR, USER],
    HMS
  );
  const isHmsEditActionAllowed = useValidUserAccess([ADMIN], HMS);
  let COLUMNS = OrphanAccountsColumn();
  if (isHmsEditActionAllowed)
    COLUMNS = [
      ...COLUMNS,
      {
        uid: "action",
        name: "Actions",
        defaultWidth: 90,
      },
    ];

  // modifies date based on days to add + strips hours from time
  const modifyDate = (date, daysToAdd) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + daysToAdd);
    newDate.setHours(0);
    const dateToString = newDate.toISOString();
    const stripHours = dateToString
      .substring(0, dateToString.indexOf("T"))
      .concat("T00:00:00.000Z");
    return stripHours;
  };

  const [filters, setFilters] = useState({
    hierarchy_name: HIERARCHY_ECH,
    search_string: "",
    // start_date_time: "",
    start_date_time: modifyDate(
      today(getLocalTimeZone()).subtract({ months: 6 }).toDate(),
      0
    ),
    end_date_time: modifyDate(today(getLocalTimeZone()).toDate(), 2),
    results_size: searchResultSize,
  });

  const orphanList = useAsyncList({
    initialFilterText: "",
    async load({ filterText, cursor }) {
      let json = {
        results: [],
      };
      const request = cursor || filters;
      if (filterText !== "") {
        setSearchLoading("loading");
        json = await hierarchyApi.fetchOrphanAccounts(
          request,
          authState.accessToken
        );
      }
      const results = json?.results || [];
      setSearchLoading("");
      return {
        items: results,
        cursor:
          results.length < searchResultSize
            ? null
            : {
                ...filters,
                end_date_time: results[results.length - 1]?.modifieddatetime,
              },
      };
    },
    getKey: (item) => item?.id,
  });

  useEffect(() => {
    orphanList.setFilterText(filters);
  }, [filters]);

  const handleSaveDuns = (newDunsId) => {
    setPageLoading(true);
    goldenRecordApi
      .updateDuns(
        {
          duns_number: newDunsId === "" ? null : newDunsId,
          modifiedby: user?.userId,
        },
        selectedOrphanAccountId,
        authState.accessToken
      )
      .then((data) => {
        setPageLoading(false);
        if (data?.success) {
          setSelectedOrphanAccountId(null);
          SuccessToast(
            `${data?.message} (wf: ${data.wf_id}) - changes may take up to a minute to reflect`,
            {
              timeout: 5000,
            }
          );
        } else {
          ErrorToast("Error updating duns number. Please try again", {
            timeout: 5000,
          });
        }
      })
      .catch((error) => {
        setPageLoading(false);
        ErrorToast(
          error.response?.data?.message || "Failed to update duns number",
          {
            timeout: 5000,
          }
        );
      });
    setFilters({ ...filters });
  };

  const renderFilters = () => (
    <Flex gap="size-150" wrap>
      <Picker
        label="Type"
        selectedKey={filters.hierarchy_name}
        onSelectionChange={(val) => {
          setFilters({ ...filters, hierarchy_name: val });
        }}
      >
        <Item key={HIERARCHY_ECH}>{HIERARCHY_ECH}</Item>
        {/* <Item key={HIERARCHY_GTM}>{HIERARCHY_GTM}</Item> */}
      </Picker>
      <SearchField
        label="Search"
        placeholder="Search by org name or id"
        onChange={(val) => {
          setFilters({ ...filters, search_string: val });
        }}
        width="250px"
      />
      <DateRangePicker
        label="Date range"
        maxValue={today(getLocalTimeZone())}
        defaultValue={{
          end: today(getLocalTimeZone()),
          start: today(getLocalTimeZone()).subtract({ months: 1 }),
        }}
        onChange={(value) =>
          setFilters((prev) => ({
            ...prev,
            start_date_time: modifyDate(value.start.toDate(), 0),
            end_date_time: modifyDate(value.end.toDate(), 1),
          }))
        }
      />
    </Flex>
  );

  const renderActionButton = (orgId, duns, rowInfo) => (
    <MenuTrigger direction="right">
      <ActionButton onPress={() => {}}>
        <MoreIcon />
      </ActionButton>
      <Menu
        onAction={(key) => {
          setSelectedOrphanAccountId(orgId);
          setOrgDetailsDuns(duns);
          setSelectedOrphan(rowInfo);
          setDialog(key);
        }}
      >
        <Item key="associate">Associate</Item>
        <Item key="duns">Update DUNS</Item>
      </Menu>
    </MenuTrigger>
  );

  const renderCell = (colKey, row) => {
    if (colKey === "org_entity_id") {
      return (
        <Text>
          <V3Link
            isQuiet
            onPress={() => {
              setOrgDetailsId(row.org_entity_id);
              setOrgSourceSystems(row.hasSourceSystem);
              setOrgDetailsOpen(true);
              setCallORGDetail(true);
            }}
          >
            {row[colKey]}
          </V3Link>
        </Text>
      );
    }
    if (colKey === "action") {
      return renderActionButton(row.org_entity_id, row.duns_number, row);
    }
    return (
      <span // eslint-disable-line jsx-a11y/no-static-element-interactions
        style={{ cursor: "text", WebkitUserSelect: "text" }}
        onPointerDown={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <Text>{row[colKey]}</Text>
      </span>
    );
  };

  const renderAssociateDialog = () => {
    if (dialog === "duns") {
      return (
        <DialogContainer
          onDismiss={() => {
            setDialog(null);
            setSelectedOrphanAccountId(null);
          }}
        >
          <EditDunsDialog
            dunsId={orgDetailsDuns}
            handleSaveDuns={handleSaveDuns}
          />
        </DialogContainer>
      );
    }
    if (dialog === "associate") {
      return (
        <DialogContainer
          onDismiss={() => {
            setDialog(null);
            setSelectedOrphanAccountId(null);
            setSelectedOrphan({});
            setFilters({ ...filters });
          }}
        >
          <DndProvider backend={HTML5Backend}>
            <AssociateExistingOrphanDialog
              orphanId={selectedOrphanAccountId}
              selectedOrphan={selectedOrphan}
            />
          </DndProvider>
        </DialogContainer>
      );
    }
    return <></>;
  };

  const handleOrgDetailsClose = () => {
    setOrgDetailsOpen(false);
    setCallORGDetail(false);
  };

  return (
    <>
      {!isHmsActionAllowed && (
        <Flex margin="size-300" gap="size-125" direction="column" width="90%">
          <View marginTop="size-200">
            You are not authorized to access this feature. To gain access please
            subscribe to <b>GRP-HMS</b> under the Groups tab. Select role{" "}
            <b>user</b> for read-only access and <b>admin</b> for edit access.
          </View>
        </Flex>
      )}
      {isHmsActionAllowed && (
        <>
          <LoadingDialog isOpen={isPageLoading} />
          <Flex margin="size-300" gap="size-125" direction="column" width="90%">
            {renderAssociateDialog()}
            {renderFilters()}
            <Flex
              marginTop="size-50"
              UNSAFE_style={{
                backgroundColor: "white",
                borderRadius: "0.5rem",
              }}
              direction="row"
              height={
                orphanList.items > 0 ? "static-size-7000" : "static-size-6000"
              }
            >
              <Table
                columns={COLUMNS}
                rows={orphanList.items}
                loadingState={
                  isSearchLoading !== ""
                    ? isSearchLoading
                    : orphanList.loadingState
                }
                onLoadMore={orphanList.loadMore}
                renderCell={renderCell}
                // selectionMode="multiple"
                selectionMode="none"
                density="compact"
              />
            </Flex>
          </Flex>
          <GetORGDetailsDialog
            open={orgDetailsOpen}
            onClose={handleOrgDetailsClose}
            orgId={orgDetailsId}
            isCalled={callORGDetail}
            sourceSystems={orgSourceSystems}
            displaySourceSystems
          ></GetORGDetailsDialog>
        </>
      )}
    </>
  );
}
