import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Heading,
  useDialogContainer,
  Flex,
  ComboBox,
  Text,
  TooltipTrigger,
  ActionButton,
  Tooltip,
  Switch,
  Item,
  useAsyncList,
} from "@adobe/react-spectrum";
import React, { useState, useEffect } from "react";
import { useDrop } from "react-dnd";
import {
  success as SuccessToast,
  error as ErrorToast,
} from "@react/react-spectrum/Toast";
import PropTypes from "prop-types";
import { useOktaAuth } from "@okta/okta-react";
import InfoOutline from "@spectrum-icons/workflow/InfoOutline";
import SortableTree, { getVisibleNodeCount } from "react-sortable-tree";
import LoadingDialog from "../LoadingDialog";
import { hierarchyApi } from "../../../api/hierarchyApi";
import DraggableComponent from "../../Common/Tree/DraggableComponent";
import useUserProfile from "../../../context/user-context";

export default function AssociateExistingOrphanDialog({
  orphanId,
  selectedOrphan,
}) {
  const HIERARCHY_FETCH_REQUEST = {};
  const [filterByDuns, setFilterByDuns] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [selectedIsSourceSystem, setSelectedIsSourceSystem] = useState(null);
  const [guName, setGuName] = useState("");
  const [newParentId, setNewParentId] = useState("");
  const [guDunsNumber, setGuDunsNumber] = useState(null);
  const [origTreeData, setOrigTreeData] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [isOrphanAttached, setOrphanAttached] = useState(false);
  const [isPageLoading, setPageLoading] = useState(false);
  const dialog = useDialogContainer();
  const { authState } = useOktaAuth();
  const { user } = useUserProfile();

  const [{ isOver }, drop] = useDrop({
    accept: "orphan",
    drop: (item, monitor) => {
      setOrphanAttached(true);
      // For example, you can update state or trigger other functions
      if (!monitor.didDrop()) {
        setTreeData(origTreeData);
        setOrphanAttached(false);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const handleError = (error) => {
    if (error && error.message.includes("Expected to find a valid target")) {
      // Handle the error here, such as logging or displaying an error message to the user
      console.error("Invalid drop position:", error);
      ErrorToast("Error Moving Node", {
        timeout: 5000,
      });
    }
  };

  const handleMoveNode = (event) => {
    setNewParentId(event?.nextParentNode?.subtitle);
  };

  const handleConfirm = () => {
    setPageLoading(true);
    hierarchyApi
      .moveNode(
        {
          hierarchy: "ECH Hierarchy",
          nodeId: orphanId,
          parentId: newParentId,
          source: "orphan",
          userId: user?.userId,
        },
        authState.accessToken
      )
      .then((message) => {
        dialog.dismiss();
        setPageLoading(false);
        SuccessToast(message.message, {
          timeout: 5000,
        });
      })
      .catch((error) => {
        dialog.dismiss();
        setPageLoading(false);
        ErrorToast(error.response?.data?.message || "Error Moving Node", {
          timeout: 5000,
        });
      });
  };

  const handleCancel = () => {
    dialog.dismiss();
  };

  const targetAccountList = useAsyncList({
    async load({ filterText }) {
      if (!filterText)
        return {
          items: [],
        };
      const json = await hierarchyApi.searchHMS(
        {
          name: filterText,
          hierarchyName: "ECH Hierarchy",
          dunsFilter: filterByDuns,
          resultsSize: 50,
        },
        authState.accessToken
      );
      const results = json?.results || [];
      return {
        items: results,
      };
    },
  });

  // traverses tree to check for expanding if searching by non root parent account
  /* eslint no-param-reassign: "error" */
  function traverse(element, nextChange) {
    element?.children?.forEach((child) => {
      if (traverse(child, nextChange)) {
        element.isChildModified = true;
        element.expanded = true;
        if (child.modified) {
          nextChange.add(element._id); // eslint-disable-line no-param-reassign, no-underscore-dangle
        }
        if (child.isSelected) {
          nextChange.add(element._id); // eslint-disable-line no-param-reassign, no-underscore-dangle
        }
      }
    });

    return (
      element &&
      (element.modified || element.isChildModified || element.isSelected)
    );
  }

  // traverses tree to check for expanding if searching by non root parent account
  const traverseTreeData = (treeNode) => {
    const temp = treeNode;
    const nextChange = new Set();
    temp.forEach((element) => {
      traverse(element, nextChange);
      nextChange.delete(element._id); // eslint-disable-line no-param-reassign, no-underscore-dangle
    });

    return temp;
  };

  const changeSuccessState = (data) => {
    setTreeData(traverseTreeData([...data]));
    setOrigTreeData(traverseTreeData([...data]));
    setOrphanAttached(false);
  };

  const getHierarchyDataByAccountId = () => {
    setPageLoading(true);
    let cleanedAccountId = selectedAccountId;
    if (cleanedAccountId != null) {
      cleanedAccountId = cleanedAccountId
        .replaceAll("true", "")
        .replaceAll("false", "");
    }
    HIERARCHY_FETCH_REQUEST.duns_number = [cleanedAccountId];
    HIERARCHY_FETCH_REQUEST.hierarchyType = "ECH Hierarchy";
    HIERARCHY_FETCH_REQUEST.isSourceSystem = selectedIsSourceSystem;
    HIERARCHY_FETCH_REQUEST.includeOrphan = true;
    hierarchyApi
      .fetchAccountById(HIERARCHY_FETCH_REQUEST, authState.accessToken)
      .then((data) => {
        setPageLoading(false);
        if (data.length === 0) {
          ErrorToast(
            "No hierarchy found (source system account id search not supported in this release)",
            {
              timeout: 5000,
            }
          );
        } else {
          setGuDunsNumber(data[0].subtitle);
          setGuName(data[0].title);
        }
        changeSuccessState(data, setTreeData);
        // targetAccountList.setFilterText(null);
        setSelectedAccountId(null);
      })
      .catch((error) => {
        setPageLoading(false);
        ErrorToast(
          error.response?.data?.message || "Error fetching hierarchy",
          {
            timeout: 5000,
          }
        );
        setTreeData([]);
      });
    setSelectedAccountId(null);
    // targetAccountList.setFilterText(null);
  };

  useEffect(() => {
    if (selectedAccountId) getHierarchyDataByAccountId();
  }, [selectedAccountId]);

  const generateProps = (rowInfo) => {
    const styleClasses = ["accountHierarchy_node"];
    let nodeProps = {};
    nodeProps = {
      title: (
        <div style={{ cursor: "context-menu" }}>
          <div>{rowInfo.node.title}</div>
        </div>
      ),
    };
    if (rowInfo?.path?.length < 4) {
      nodeProps.subtitle = (
        <div className="nodeSubtitle">
          <div>{rowInfo?.node?.subtitle}</div>
          <div>{`${rowInfo?.node?.street} ${rowInfo?.node?.city}, ${rowInfo?.node?.state} ${rowInfo?.node?.country}`}</div>
        </div>
      );
    }
    nodeProps.title = (
      <div className="AHtooltip">
        {rowInfo?.node?.title}
        <div className="right">
          <div className="text-content">
            <Heading
              level={3}
            >{`${rowInfo?.node?.street} ${rowInfo?.node?.city}, ${rowInfo?.node?.state} ${rowInfo?.node?.country}`}</Heading>
          </div>
          <i></i>
        </div>
      </div>
    );

    if (rowInfo.node.isSelected) styleClasses.push("cam_tree_node_selected");
    if (rowInfo.node?.isSourceSystem) styleClasses.push("source_system_node");
    if (rowInfo.node.orphan) styleClasses.push("cam_tree_node_modified");

    nodeProps.className = styleClasses.join(" ");
    return nodeProps;
  };

  const handleCanDrop = (nodeEvent) => {
    if (
      nodeEvent?.nextParent?.isSourceSystem ||
      nodeEvent?.nextParent === null
    ) {
      return false;
    }
    return true;
  };

  const handleCanDrag = (nodeEvent) => {
    if (nodeEvent?.node?.orphan) {
      return true;
    }
    return false;
  };

  return (
    <Dialog width="1000px" height="90vh">
      <LoadingDialog isOpen={isPageLoading} />
      <Heading
        level={1}
      >{`Associate ${orphanId} to an existing hierarchy`}</Heading>
      <Divider />
      <Content>
        <Flex direction="column">
          <div
            style={
              !isPageLoading
                ? {
                    position: "sticky",
                    top: 0,
                    backgroundColor: "#fff",
                    borderBottom: "1px solid #ccc",
                    zIndex: 1000,
                  }
                : {}
            }
          >
            <Flex direction="row" marginBottom="20px">
              <ComboBox
                label={
                  <Flex height="32px" alignItems="center">
                    <Flex width="200px">
                      <Text UNSAFE_style={{ fontSize: "14px" }}>
                        Search account
                      </Text>
                      <TooltipTrigger>
                        <ActionButton
                          isQuiet
                          aria-label="Information"
                          height="20px"
                        >
                          <InfoOutline size="S" />
                        </ActionButton>
                        <Tooltip>
                          Blue dropdown items are top parent accounts. Non-blue
                          items belong to accounts deeper in the hierarchy
                        </Tooltip>
                      </TooltipTrigger>
                    </Flex>
                    <Flex direction="column" alignItems="end" width="200px">
                      <Switch
                        isSelected={filterByDuns}
                        onChange={setFilterByDuns}
                        marginEnd="-8px"
                      >
                        <Text>DUNS Only</Text>
                      </Switch>
                    </Flex>
                  </Flex>
                }
                placeholder="Search by org account name, orgid, ssid, or duns"
                menuTrigger="focus"
                onSelectionChange={(val) => {
                  setSelectedAccountId(val);
                  setSelectedIsSourceSystem(
                    val && val.length > 1 && val.includes("true")
                  );
                }}
                onInputChange={(val) => {
                  targetAccountList.setFilterText(val);
                }}
                items={targetAccountList.items}
                loadingState={targetAccountList.loadingState}
                width="400px"
              >
                {(item) =>
                  item !== undefined && (
                    <Item key={item.id + item?.isSourceSystem.toString()}>
                      <Text>
                        {item.isRoot ? (
                          <b style={{ color: "darkblue" }}>{`${
                            item.name
                          } | orgid: ${item.id} | duns: ${
                            item.duns_number === "" ? "null" : item.duns_number
                          }`}</b>
                        ) : (
                          `${item.name} | orgid: ${item.id} | duns: ${
                            item.duns_number === "" ? "null" : item.duns_number
                          }`
                        )}
                      </Text>
                    </Item>
                  )
                }
              </ComboBox>
              <Flex direction="row" alignItems="end">
                <Flex direction="column" marginStart="20px">
                  <Text>1. Search for an existing hierarchy</Text>
                  <Text>
                    2. Drag orphan node to an appropriate location in the
                    hierarchy
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex>
              {!isOrphanAttached && (
                <Flex
                  marginStart="10px"
                  alignItems="end"
                  marginBottom="10px"
                  gap="10px"
                >
                  <DraggableComponent
                    node={{
                      title: selectedOrphan.name,
                      subtitle: orphanId,
                      street: selectedOrphan?.street_1,
                      city: selectedOrphan?.city,
                      country: selectedOrphan?.country,
                      state: selectedOrphan?.state,
                      orphan: true,
                    }}
                  />
                  <Text>&larr; orphan account</Text>
                </Flex>
              )}
            </Flex>
          </div>
          {treeData.length >= 1 && (
            <Flex width="100%" direction="column" marginTop="10px">
              <Heading level={3}>{`${guName} | ${guDunsNumber}`}</Heading>
              <div
                style={{
                  height:
                    getVisibleNodeCount({ treeData }) * 70 + 25 < 440
                      ? 440
                      : getVisibleNodeCount({ treeData }) * 70 + 25,
                  backgroundColor: isOver ? "lightblue" : "white",
                }}
                ref={drop}
              >
                <SortableTree
                  className="tree-sortabletree"
                  treeData={treeData}
                  onChange={setTreeData}
                  canDrag={(object) => handleCanDrag(object)}
                  canDrop={(object) => handleCanDrop(object)}
                  generateNodeProps={(rowInfo) => generateProps(rowInfo)}
                  expanded
                  rowHeight={70}
                  flex={1}
                  dndType="orphan"
                  onError={handleError}
                  onMoveNode={handleMoveNode}
                />
              </div>
            </Flex>
          )}
        </Flex>
      </Content>
      <ButtonGroup>
        <Button onPress={handleCancel} variant="secondary">
          Cancel
        </Button>
        <Button
          onPress={handleConfirm}
          color="primary"
          variant="accent"
          isDisabled={!isOrphanAttached}
        >
          Confirm
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}
AssociateExistingOrphanDialog.defaultProps = {
  selectedOrphan: {},
};
AssociateExistingOrphanDialog.propTypes = {
  orphanId: PropTypes.string.isRequired,
  selectedOrphan: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  ),
};
